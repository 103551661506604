import { Outlet, useLoaderData } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../utils/envConfig";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  getEditorDefaults,
  openEditor,
  plugin_crop,
  processImage,
  setPlugins,
} from "../../scripts/pintura";
import { FormImage } from "../../utils";
import { prepareImages } from "../../utils/images";
import { Customer } from "@medusajs/medusa";
import { toast } from "react-toastify";
import { LoadingContext } from "../..";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

const VerifyAccountFragment = () => {
  const schema = yup
    .object({
      ssn: yup.string().optional(),
    })
    .required();
    
  const customer: Customer = useLoaderData() as Customer;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ssn: string}>({
    defaultValues: {
      ssn: customer.metadata?.ssn,
      ssn_image: customer.metadata?.ssn_image ?? []
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { replace: replaceSSNImage , fields: ssnImageFields} = useFieldArray({
    control: control,
    name: "ssn_image",
  } as any);
  
  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { client: medusaClient } = useMedusa();
  const { setLoading } = useContext(LoadingContext);
  const updateCustomerProfileAction = async (
    data: {ssn: string, ssn_image?: FormImage[] }
  ) => {
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.ssn_image ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }
    
    medusaClient.customers
      .update(
        {
          metadata: {...customer.metadata, ssn_image: preppedImages, ssn: data.ssn },
        },
        {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        }
      )
      .then((res) => {
        // show toaster
        toast.success("Verification Documents Updated");
      })
      .catch((err) => {
        // show toaster
        // show toaster
        toast.error(err?.response?.data?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };


  return (
    <>
      <div className="xl:grid grid-cols-12 gap-12 flex 2xl:flex-row flex-col-reverse">
        <div className="2xl:col-span-8 xl:col-span-7">
          <h3 className="text-2xl font-bold pb-5 text-bgray-900 dark:text-white dark:border-darkblack-400 border-b border-bgray-200">
            Account Verification
          </h3>
          <div className="mt-8">
            <form onSubmit={handleSubmit(updateCustomerProfileAction)}>
              <div className="grid 2xl:grid-cols-2 grid-cols-1 gap-6">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="ssn"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Social Security Number (Optional)
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("ssn", { required: false })}
                    
                  />
                  
                  {errors.ssn?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.ssn.message}
                    </p>
                  )}
                  
                  <label
                    className="mt-4 text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Identification Documents
                  </label>
                  {useMemo(
        () => (
              <FilePond
                filePosterMaxHeight={200}
                acceptedFileTypes={[
                  "image/gif",
                  "image/jpeg",
                  "image/png",
                  "image/webp",
                ]}
                server={{
                  load: (source, load, error, progress, abort, headers) => {
                    var myRequest = new Request(source)
                    fetch(myRequest).then(function (response) {
                      response.blob().then(function (myBlob) {
                        load(myBlob)
                      })
                    }).catch(error)
                  },
                }}
                files={ssnImageFields.map((field) => ({
                  options: {
                    type: "local",
                  },
                  source: (field as any).url,
                }))}
                // imageEditEditor={createEditor}
                imageEditor={imageEditorOptions}
                // imageEditorInstantEdit={true}
                onaddfile={(_, file) => {
                  if (_) return console.error(_);
                  replaceSSNImage([
                    {
                      url: URL.createObjectURL(file.file),
                      name: file.filename,
                      size: file.fileSize,
                      nativeFile: file.file as any,
                    },
                  ]);
                }}
                allowMultiple={false}
                name="files" /* sets the file input name, it's filepond by default */
                labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
              />),
              []
            )}
                </div>
              </div>
              <div className="flex justify-end">
                <button className="rounded-lg bg-success-300 text-white font-semibold mt-10 py-3.5 px-4">
                  Save Profile
                </button>
              </div>
            </form>
          </div>
        </div>
        
        
      </div>
    </>
  );
};
export default VerifyAccountFragment;
