import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DialogCloseIcon } from "../../../icons/user.icon";
import { BASE_URL, Investment, Withdrawal } from "../../../utils";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";
import { toast } from "react-toastify";

export const WithdrawInvestmentPlanDialog = (props: {
  investment?: Investment;
  isOpen: boolean;
  closeModal: (withdrawal?: Withdrawal) => void;
}) => {
  const schema = yup
    .object({
      amount: yup
        .number()
        .required()
        .min(100)
        .max(props.investment?.balance ?? 0),
      reason: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{ amount: number; reason: string }>({
    defaultValues: {
      amount: 0,
      reason: null,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const createWithdrawalRequest = async (data: { amount: number }) => {
    console.error(data);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/withdrawals`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
        productVariantId: props.investment!.productVariantId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        const { withdrawal }: { withdrawal: Withdrawal } =
          await response.json();
        // close dialog

        toast.warning(
          "Your wallet would be credited after successful confirmation"
        );

        props.closeModal(withdrawal);
        reset();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      });
  };

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}

              <div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6">
                {/* inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6 */}
                <header>
                  <div>
                    <h3 className="font-bold text-bgray-900 dark:text-white text-2xl mb-1">
                      Make a withdrawal
                    </h3>
                  </div>
                  <div className="absolute top-0 right-0 pt-5 pr-5">
                    <button
                      type="button"
                      id="step-1-cancel"
                      onClick={() => props.closeModal()}
                      className="rounded-md bg-white dark:bg-darkblack-500 focus:outline-none"
                    >
                      <span className="sr-only">Close</span>
                      <DialogCloseIcon />
                    </button>
                  </div>
                </header>
                <form onSubmit={handleSubmit(createWithdrawalRequest)}>
                  <div className="">
                    <div className="payment-select relative mb-3">
                      <label
                        htmlFor="chn"
                        className="block mb-3 text-base font-medium text-bgray-600 dark:text-darkblack-300"
                      >
                        How much would you like to withdraw?
                      </label>
                      <button
                        type="button"
                        className="px-5 w-full h-[56px] border border-bgray-200 dark:border-darkblack-400 flex justify-between items-center rounded-lg overflow-hidden"
                      >
                        <div className="flex space-x-2 items-center">
                          <span>
                            <img
                              src="/images/payments/tether_usdt_icon.svg"
                              width={100}
                              alt="master"
                            />
                          </span>
                        </div>
                        <div className="flex space-x-2 items-center"></div>
                      </button>
                    </div>
                    <div className="w-full rounded-lg border border-bgray-200 dark:border-darkblack-400 focus-within:border-success-300 p-4 h-[98px] flex flex-col justify-between">
                      <p className="text-sm text-bgray-600 dark:text-darkblack-300 font-medium">
                        Enter amount
                      </p>
                      <div className="w-full h-[35px] flex justify-between items-center">
                        <span className="text-2xl text-bgray-900 dark:text-white font-bold">
                          $
                        </span>
                        <label className="w-full">
                          <input
                            type="number"
                            {...register("amount", { required: true })}
                            className="focus:outline-none w-full p-0 focus:ring-0 border-none text-2xl font-bold text-bgray-900 dark:bg-darkblack-600 dark:text-white"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                      <label
                        htmlFor="reason"
                        className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                      >
                        Reason for withdrawing
                      </label>
                      <textarea
                        className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                        {...register("reason", { required: true })}
                      />
                      {errors.reason?.message && (
                        <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                          {errors.reason.message}
                        </p>
                      )}
                    </div>
                    {errors.amount?.message && (
                      <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                        {errors.amount.message}
                      </p>
                    )}
                  </div>
                  <div
                    className="flex justify-center pt-11"
                    onClick={() => handleSubmit(createWithdrawalRequest)}
                  >
                    <button className="bg-success-300 hover:bg-success-400 text-white font-semibold text-sm py-4 flex justify-center items-center rounded-lg px-20">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
