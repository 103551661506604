import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouteGuard } from "react-guardian";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Home from "./routes/home";
import Root from "./routes/root";

import LoginPage from "./routes/login";
import { loggedInOnlyPolicy } from "./policies";
import { MedusaProvider } from "medusa-react";
import { QueryClient } from "@tanstack/react-query";
import Medusa from "@medusajs/medusa-js";
import HomePage from "./routes/home";
import LogoutIcon from "./icons/logout.icon";
import PersonalInformationFragment from "./routes/profile_fragments/personal_information_fragment";
import SecurityFragment from "./routes/profile_fragments/security_fragment";
import FAQsFragment from "./routes/profile_fragments/faqs_fragment";
import VerifyAccountFragment from "./routes/profile_fragments/verify_account_fragment";
import ProfilePage from "./routes/profile";
import PlansPage from "./routes/plans";
import AvailablePlansFragment from "./routes/plans_fragments/available_plans";
import MyPlansFragment from "./routes/plans_fragments/my_plans";
import MyWalletPage from "./routes/my-wallet";
import { LoadingDialog } from "./components/loading_dialog";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "./utils/envConfig";
import { Store } from "@medusajs/medusa";
import { BASE_URL, Investment } from "./utils";
import DepositHistoryTable from "./routes/plans_fragments/components/deposit_history";
import WithdrawalRequestsTable from "./routes/plans_fragments/components/withdrawal_requests_history";
import StatementsPage from "./routes/statements";
import FundWalletComponent from "./routes/plans_fragments/components/fund_wallet_component";
import RegisterPage from "./routes/register";
import MobileMenu from "./components/mobile_menu";

const medusa = new Medusa({
  baseUrl: BASE_URL,
  maxRetries: 2,
});

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      id="root"
      loader={async (params) => {
        const req = await fetch(`${BASE_URL}store/domain/info`);

        if (req.status >= 400 && req.status < 600) {
          const { message }: { message: string } = await req.json();
          throw new Error(message);
        }

        const { store }: { store: Store } = await req.json();

        return { store };
      }}
    >
      <Route
        path="home"
        element={
          <RouteGuard policies={[loggedInOnlyPolicy]}>
            <HomePage />
          </RouteGuard>
        }
        children={[
          <Route
            path="profile/*"
            element={<ProfilePage />}
            children={[
              <Route
                loader={async (params) => {
                  const { customer } = await medusa.customers.retrieve({
                    Authorization: `Bearer ${localStorage.getItem(
                      LOCAL_STORAGE_ACCESS_TOKEN_KEY
                    )}`,
                  });

                  return customer;
                }}
                path="update"
                element={<PersonalInformationFragment />}
              />,
              <Route path="security" element={<SecurityFragment />} />,
              <Route path="faqs" element={<FAQsFragment />} />,
              <Route
                path="verify"
                loader={async (params) => {
                  const { customer } = await medusa.customers.retrieve({
                    Authorization: `Bearer ${localStorage.getItem(
                      LOCAL_STORAGE_ACCESS_TOKEN_KEY
                    )}`,
                  });

                  return customer;
                }}
                element={<VerifyAccountFragment />}
              />,
              <Route index element={<Navigate to="update" />} />,
            ]}
          ></Route>,
          <Route
            path="statement/*"
            element={<StatementsPage />}
            children={[
              <Route
                path="deposits"
                element={
                  <>
                    <DepositHistoryTable />
                  </>
                }
              />,
              <Route
                path="fund-wallet"
                
                element={
                  <>
                    <FundWalletComponent />
                  </>
                }
              />,
              <Route
                path="withdrawals"
                element={<WithdrawalRequestsTable />}
              />,
              <Route index element={<Navigate to="deposits" />} />,
            ]}
          ></Route>,
          <Route
            path="plans/*"
            element={<PlansPage />}
            children={[
              <Route
                path="available"
                loader={async (params) => {
                  let { products } = await medusa.products.list(
                    { currency_code: "usd" },
                    {
                      Authorization: `Bearer ${localStorage.getItem(
                        LOCAL_STORAGE_ACCESS_TOKEN_KEY
                      )}`,
                    }
                  );

                  // load preferred products
                  const req = await fetch(
                    `${BASE_URL}store/investments/recommended`,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          LOCAL_STORAGE_ACCESS_TOKEN_KEY
                        )}`,
                      },
                    }
                  );
                  if (req.status >= 400 && req.status < 600) {
                    const { message }: { message: string } = await req.json();
                    throw new Error(message);
                  }

                  const { productIds }: { productIds: string[] | undefined } =
                    await req.json();

                  if (productIds && productIds.length > 0) {
                    products = products.filter(
                      (x) => productIds.indexOf(x.id!) > -1
                    );
                  }

                  return products;
                }}
                element={<AvailablePlansFragment />}
              />,
              <Route
                loader={async (params) => {
                  const req = await fetch(`${BASE_URL}store/investments`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        LOCAL_STORAGE_ACCESS_TOKEN_KEY
                      )}`,
                    },
                  });
                  if (req.status >= 400 && req.status < 600) {
                    const { message }: { message: string } = await req.json();
                    throw new Error(message);
                  }

                  const { investments }: { investments: Investment[] } =
                    await req.json();

                  return investments;
                }}
                path="subscribed"
                element={<MyPlansFragment />}
              />,
              <Route index element={<Navigate to="subscribed" />} />,
            ]}
          ></Route>,
          <Route
            index
            loader={async (params) => {
              const { customer } = await medusa.customers.retrieve({
                Authorization: `Bearer ${localStorage.getItem(
                  LOCAL_STORAGE_ACCESS_TOKEN_KEY
                )}`,
              });

              return customer;
            }}
            element={<MobileMenu />}
          />,
        ]}
      />

      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const LoadingContext = createContext<{
  loading: boolean;
  setLoading?: (loading: boolean) => void;
}>({ loading: false });

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <link
        rel="stylesheet"
        href="/css/font-awesome-all.min.css"
        type="text/css"
      />

      <React.StrictMode>
        <MedusaProvider
          medusaClient={medusa}
          queryClientProviderProps={{ client: queryClient }}
          baseUrl={BASE_URL}
        >
          <LoadingContext.Provider
            value={{ loading: loading, setLoading: setLoading }}
          >
            <RouterProvider router={router} />
          </LoadingContext.Provider>
        </MedusaProvider>
      </React.StrictMode>
    </>
  );
};

root.render(<App></App>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
