import React from "react";
import { timeout } from "../../login";
import { LoadingRing, LoadingRingMaxW } from "../../../components/loading_dialog";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";
import { Account, BASE_URL, StoreDepositAddress } from "../../../utils";
import { toast } from "react-toastify";
import { useRouteLoaderData } from "react-router-dom";
import { Store } from "@medusajs/medusa";
import { isNil } from "lodash";

export class WalletBalancesCardComponent extends React.Component<
  {
    setMetadata?: (data: {
      accounts?: Account[];
      depositAddresses?: StoreDepositAddress[];
    }) => void;
  },
  { accounts: Account[]; loadingError?: string; store?: Store }
> {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      loadingError: undefined,
      store: undefined,
    };
    this.cardRef = React.createRef();
  }
  cardRef: any;


  async refresh() {
    this.setState({ ...this.state, loadingError: undefined });
     Promise.all([
      fetch(`${BASE_URL}store/wallet/init`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }),
      // create the deposit and dispose
    fetch(`${BASE_URL}store/wallet`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    }),fetch(`${BASE_URL}store/domain/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),fetch(`${BASE_URL}store/investments/recommended`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })

    ]).then(async ([r1, r2, r3, r4]) => {
      if (r1.status >= 400 && r1.status < 600) {
        const { message }: { message: string } = await r1.json();
        throw new Error(message);
      }
      if (r2.status >= 400 && r2.status < 600) {
        const { message }: { message: string } = await r2.json();
        throw new Error(message);
      }
      if (r3.status >= 400 && r3.status < 600) {
        const { message }: { message: string } = await r3.json();
        throw new Error(message);
      }
      if (r4.status >= 400 && r4.status < 600) {
        const { message }: { message: string } = await r4.json();
        throw new Error(message);
      }
      const { store }: { store: Store } = await r3.json();
      const { addresses: handlerAddresses }: { productIds: string[], addresses: StoreDepositAddress[] } = await r4.json();
      const {
        accounts,
        lockedAccounts,
      }: { accounts: Account[]; lockedAccounts: Account[] } =
        await r2.json();
        this.setState({...this.state, store, accounts })
        
        this.props.setMetadata && this.props.setMetadata({
          accounts: accounts,
          depositAddresses: handlerAddresses ?? (store.metadata as any).addresses as any,
        });

    }).catch((error) => {
      console.error(error);
      this.setState({
        ...this.state,
        loadingError: error ?? "An error occured",
      });
    });
  }

  componentDidMount(): void {
    setTimeout(() => {

      this.refresh();
    }, 1200)
  }

  render() {
    if (this.state.loadingError)
      return (
        <div className="w-full rounded-xl bg-white dark:bg-darkblack-600 mb-[48px]">
          <div className="border border-bgray-300 dark:border-darkblack-400 rounded-lg p-8 pb-12">
            <div className="flex gap-4">
              <span className="text-base font-medium text-bgray-500 dark:text-darkblack-300">
                {this.state.loadingError}
              </span>
            </div>
          </div>
          <div className="flex justify-center -mt-6">
            <button
              onClick={() => this.refresh()}
              className="py-3 px-6 border bg-white border-bgray-500 rounded-lg text-sm font-medium text-bgray-600 dark:bg-darkblack-600 dark:text-white"
            >
              Retry
            </button>
          </div>
        </div>
      );

    return this.state.accounts.length <= 0 ||
      isNil(this.state.store?.metadata?.addresses) ? (
        <>
        <LoadingRingMaxW />
         </>
    ) : (
    <>
    </>
        
    );
  }
}
