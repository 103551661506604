import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../utils/envConfig";

import { ToastContainer, toast } from "react-toastify";
import { LoadingContext } from "../..";
import { timeout } from "../login";
import { useLoaderData } from "react-router-dom";
import { Customer } from "@medusajs/medusa";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageEditor from "@pqina/filepond-plugin-image-editor";
// import FilePondPluginImageEditor from "../../scripts/filepond-plugin-image-editor"
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  getEditorDefaults,
  openEditor,
  plugin_crop,
  processImage,
  setPlugins,
} from "../../scripts/pintura";
import { FormImage } from "../../utils";
import { prepareImages } from "../../utils/images";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginImageEditor,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

setPlugins(plugin_crop);

export type PersonalInformationFormType = {
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  phone: string;
  email: string;
  postal_code?: string;
  referralCode?: string;
  prefix?: string;
  avatar?: FormImage[];
};

const PersonalInformationFragment = () => {
  const schema = yup
    .object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      phone: yup.string(),
      email: yup.string(),
      address: yup.string().nullable().optional().transform((curr, orig) => (orig === "" ? null : curr)),
      city: yup.string().nullable().optional().transform((curr, orig) => (orig === "" ? null : curr)),
      postal_code: yup.string().nullable().optional().transform((curr, orig) => (orig === "" ? null : curr)),
      prefix: yup.string().nullable().optional().transform((curr, orig) => (orig === "" ? null : curr)),
    })
    .required();

  const customer: Customer = useLoaderData() as Customer;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PersonalInformationFormType>({
    defaultValues: {
      address: customer.billing_address?.address_1,
      city: customer.billing_address?.city,
      country: customer.billing_address?.country_code,
      postal_code: customer.billing_address?.postal_code,
      email: customer.email,
      first_name: customer.first_name,
      referralCode: (customer as any).referralCode,
      last_name: customer.last_name,
      phone: customer.phone,
      avatar: customer.metadata?.avatar ?? [],
      prefix: customer.metadata?.prefix ?? '+1'
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { replace: replaceProfileImage , fields: avatarFields} = useFieldArray({
    control: control,
    name: "avatar",
  } as any);

  const imageEditorOptions = {
    // Maps legacy data objects to new imageState objects (optional)
    // legacyDataToImageState: legacyDataToImageState,

    // Used to create the editor (required)
    createEditor: openEditor,

    // Used for reading the image data. See JavaScript installation for details on the `imageReader` property (required)
    imageReader: [createDefaultImageReader],

    // Required when generating a preview thumbnail and/or output image
    imageWriter: [createDefaultImageWriter],

    // Used to create poster and output images, runs an invisible "headless" editor instance
    imageProcessor: processImage,

    // Pintura Image Editor options
    editorOptions: {
      // Pass the editor default configuration options
      ...getEditorDefaults(),

      // This will set a square crop aspect ratio
      // placeholder="1200 x 1600 (3:4) recommended, up to 10MB each"
      imageCropAspectRatio: 1,
    },
  };

  const { client: medusaClient } = useMedusa();
  const [state, setState] = useState({});
  const { setLoading } = useContext(LoadingContext);

  const updateCustomerProfileAction = async (
    data: PersonalInformationFormType
  ) => {
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.avatar ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    medusaClient.customers
      .update(
        {
          first_name: data.first_name,
          last_name: data.last_name,
          billing_address: {
            address_1: data.address,
            city: data.city,
            phone: data.phone,
            postal_code: data.postal_code,
            // country_code: data.country,
          },
          metadata: {...customer.metadata, avatar: preppedImages, prefix: data.prefix },
        },
        {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        }
      )
      .then((res) => {
        // show toaster
        toast.success("Profile Updated");
      })
      .catch((err) => {
        // show toaster
        toast.error(err?.response?.data?.message ?? "An error occured");
      })
      .finally(() => {
        setLoading!(false);
      });
  };

  return (
    <>
      <div className="xl:grid grid-cols-12 gap-12 flex 2xl:flex-row flex-col-reverse">
        <div className="2xl:col-span-8 xl:col-span-7">
          <h3 className="text-2xl font-bold pb-5 text-bgray-900 dark:text-white dark:border-darkblack-400 border-b border-bgray-200">
            Personal Information
          </h3>
          <div className="mt-8">
            <form onSubmit={handleSubmit(updateCustomerProfileAction)}>
              <div className="grid 2xl:grid-cols-2 grid-cols-1 gap-6">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="first_name"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.first_name.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="last_name"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.last_name.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="email"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("email", { disabled: true })}
                  />
                  {errors.email?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="grid grid-cols-12 gap-6">

                <div className="flex flex-col gap-2 col-span-4">
                  <label
                    htmlFor="phone"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Prefix
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("prefix", { required: true })}
                  />
                  {errors.prefix?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.prefix.message}
                    </p>
                  )}
                </div>

                <div className="flex flex-col gap-2  col-span-8">
                  <label
                    htmlFor="phone"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Phone Number (Optional)
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("phone", { required: true })}
                  />
                  {errors.phone?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.phone.message}
                    </p>
                  )}
                </div>
                </div>
                {<div className="flex flex-col gap-2">
                  <label
                    htmlFor="phone"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    Referral Code
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("referralCode", { required: true, disabled: true })}
                  />
                </div>}
              </div>
              <h4 className="pt-8 pb-6 text-xl font-bold text-bgray-900 dark:text-white">
                Personal Address
              </h4>
              <div className="grid 2xl:grid-cols-2 grid-cols-1 gap-6">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="address"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium "
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("address", {})}
                  />
                  {errors.address?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.address.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="city"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("city", { required: true })}
                  />
                  {errors.city?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.city.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="postal_code"
                    className="text-base text-bgray-600 dark:text-bgray-50  font-medium "
                  >
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                    {...register("postal_code", {})}
                  />
                  {errors.postal_code?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.postal_code.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleSubmit(updateCustomerProfileAction)}
                  className="rounded-lg bg-success-300 text-white font-semibold mt-10 py-3.5 px-4"
                >
                  Save Profile
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="2xl:col-span-4 xl:col-span-5 2xl:mt-24">
          <header className="mb-8">
            <h4 className="font-bold text-lg text-bgray-800 dark:text-white mb-2">
              Update Profile
            </h4>
            <p className="mb-4 text-bgray-500">
              Profile of at least Size
              <span className="text-bgray-900 dark:text-darkblack-300">
                300x300.
              </span>{" "}
              <span className="text-bgray-900">Max 5mb.</span>
            </p>
            <div className="text-center m-auto w-40 h-40 relative">
            {useMemo(
        () => (
              <FilePond
                filePosterMaxHeight={200}
                maxFileSize={"3072KB"}
                stylePanelLayout={"compact circle"}
                styleLoadIndicatorPosition="center bottom"
                styleProgressIndicatorPosition="right bottom"
                styleButtonRemoveItemPosition="left bottom"
                styleButtonProcessItemPosition="right bottom"
                acceptedFileTypes={[
                  "image/gif",
                  "image/jpeg",
                  "image/png",
                  "image/webp",
                ]}
                server={{
                  load: (source, load, error, progress, abort, headers) => {
                    var myRequest = new Request(source)
                    fetch(myRequest).then(function (response) {
                      response.blob().then(function (myBlob) {
                        load(myBlob)
                      })
                    }).catch(error)
                  },
                }}
                files={avatarFields.map((field) => ({
                  options: {
                    type: "local",
                  },
                  source: (field as any).url,
                }))}
                // imageEditEditor={createEditor}
                imageEditor={imageEditorOptions}
                // imageEditorInstantEdit={true}
                onaddfile={(_, file) => {
                  if (_) return console.error(_);
                  replaceProfileImage([
                    {
                      url: URL.createObjectURL(file.file),
                      name: file.filename,
                      size: file.fileSize,
                      nativeFile: file.file as any,
                    },
                  ]);
                }}
                allowMultiple={false}
                name="files" /* sets the file input name, it's filepond by default */
                labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
              />),
              []
            )}
            </div>
          </header>
        </div>
      </div>
    </>
  );
};
export default PersonalInformationFragment;
