import { Outlet } from "react-router-dom";
import { EyeIcon } from "../../icons/user.icon";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../utils/envConfig";
import { ToastContainer, toast } from "react-toastify";
import { LoadingContext } from "../..";

export type ChangePasswordFormType = {
  new_password: string;
  confirm_password: string;
};

const SecurityFragment = () => {
  const schema = yup
    .object({
      new_password: yup.string().min(6).required(),
      confirm_password: yup
        .string()
        .min(6)
        .oneOf([yup.ref("new_password"), undefined], "Passwords don't match")
        .required("Confirm Password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ChangePasswordFormType>({
    defaultValues: {
      new_password: null,
      confirm_password: null,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { client: medusaClient } = useMedusa();
  const { setLoading } = useContext(LoadingContext);

  const [state, setState] = useState({
    obscurePass: true,
  });


  const changeAccountPassword = async (data: ChangePasswordFormType) => {
    setState({ ...state });
    setLoading!(true);
    medusaClient.customers
      .update(
        {
          password: data.new_password,
        },
        {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        }
      )
      .then((res) => {
        toast.success("Password Updated");
        setLoading!(false);
      })
      .catch((err) => {
        // show toaster
        toast.error(err?.response?.data?.message ?? "An error occured");
        setLoading!(false);
      });

  };
  return (
    <>
      <div className="flex flex-col gap-10 xl:flex-row xl:items-center">
        <div className="grow max-w-[614px]">
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-white mb-3">
            Password
          </h3>
          <p className="text-sm fotn-medium text-bgray-500 dark:text-bgray-50">
            Change or view your password.
          </p>
          <form onSubmit={handleSubmit(changeAccountPassword)} className="mt-6">
            <div className="relative flex flex-col mb-6">
              <label
                htmlFor="old"
                className="text-sm block mb-3 font-medium text-bgray-500 dark:text-darkblack-300"
              >
                New password
              </label>
              <input
                    type={state.obscurePass ? "password" : "text"}
                className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white rounded-lg w-full h-14 px-4 py-5 border-0 focus:border focus:border-success-300 focus:ring-0"
                {...register("new_password", { required: true })}
              />
              <button
                type="button"
                onClick={() =>
                  setState({
                    ...state,
                    obscurePass: !state.obscurePass,
                  })
                }
                className="absolute right-4 top-12"
              >
                <EyeIcon />
              </button>
            </div>
            <div className="relative flex flex-col mb-6">
              <label
                htmlFor="old"
                className="text-sm block mb-3 font-medium text-bgray-500 dark:text-darkblack-300"
              >
                Confirm password
              </label>
              <input
                    type={state.obscurePass ? "password" : "text"}
                className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white rounded-lg w-full h-14 px-4 py-5 border-0 focus:border focus:border-success-300 focus:ring-0"
                {...register("confirm_password", { required: true })}
              />

              <button
                type="button"
                onClick={() =>
                  setState({
                    ...state,
                    obscurePass: !state.obscurePass,
                  })
                }
                className="absolute right-4 top-12"
              >
                <EyeIcon />
              </button>
              <small className="text-xs text-bgray-500 dark:text-darkblack-300 block mt-1">
                Minimum 6 characters
              </small>
            </div>
            {(errors.new_password?.message ||
              errors.confirm_password?.message) && (
              <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                {errors.new_password?.message ??
                  errors.confirm_password?.message}
              </p>
            )}
            <div className="flex justify-end">
              <button
                onClick={handleSubmit(changeAccountPassword)}
                className="text-sm bg-success-300 hover:bg-success-400 transition-all py-3 px-4 text-white font-semibold rounded-lg hover:bg-opacity-100"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
        <div className="mx-auto pt-10 hidden xl:block">
          <img src="/images/illustration/reset-password.svg" alt="" />
        </div>
      </div>
    </>
  );
};
export default SecurityFragment;
