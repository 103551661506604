import Medusa from "@medusajs/medusa-js";
import { FormImage } from ".";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "./envConfig";

const splitImages = (
  images: FormImage[]
): { uploadImages: FormImage[]; existingImages: FormImage[] } => {
  const uploadImages: FormImage[] = [];
  const existingImages: FormImage[] = [];

  images.forEach((image) => {
    if (image.nativeFile) {
      uploadImages.push(image);
    } else {
      existingImages.push(image);
    }
  });

  return { uploadImages, existingImages };
};

export const prepareImages = async (
  medusaClient: Medusa,
  images: FormImage[]
) => {
  const { uploadImages, existingImages } = splitImages(images);

  let uploadedImgs: FormImage[] = [];
  if (uploadImages.length > 0) {
    const files = uploadImages.map((i) => i.nativeFile!);

    const formData = new FormData();
    for (const f of files) {
      formData.append("files", f);
    }

    const response = await medusaClient.client.request(
      "POST",
      "/store/domain/uploads",
      formData,
      {},
      {
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      }
    );
    uploadedImgs = response.uploads;
  }

  return [...existingImages, ...uploadedImgs];
};
