import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Store } from "@medusajs/medusa";
import { Fragment, useContext, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { DialogCloseIcon } from "../icons/user.icon";
import { BASE_URL, Deposit, FormImage, StoreDepositAddress } from "../utils";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { useMedusa } from "medusa-react";
import QRCode from "react-qr-code";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingContext } from "..";
import { prepareImages } from "../utils/images";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";

// Register the plugins
registerPlugin(
  /* FilePondPluginImageExifOrientation, FilePondPluginImagePreview, */
  FilePondPluginFileValidateType,
  FilePondPluginFilePoster,
  FilePondPluginFileValidateSize
);

export type DepositFormType = {
  proof?: FormImage[];
  amount?: number;
};

export const DepositDialog = (props: {
  depositAddress?: StoreDepositAddress;
  isOpen: boolean;
  closeModal: (deposit?: Deposit) => void;
}) => {
  const schema = yup
    .object({
      amount: yup.number().required(),
      proof: yup.array().min(1).max(1).label('Receipt'),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,reset,
    setValue,
    formState: { errors },
  } = useForm<DepositFormType>({
    defaultValues: {
      proof: [],
      amount: 0
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { replace: replaceProofImage, fields: proofImageFields } =
    useFieldArray({
      control: control,
      name: "proof",
    } as any);

  const { store } = useRouteLoaderData("root") as { store: Store };
  const { client: medusaClient } = useMedusa();
  const { setLoading } = useContext(LoadingContext);

  const createDeposit = async (data: DepositFormType) => {
    setLoading!(true);

    let preppedImages: FormImage[] = [];

    try {
      preppedImages = await prepareImages(medusaClient, data.proof ?? []);
    } catch (error) {
      let errorMessage =
        "Something went wrong while trying to upload the thumbnail.";

      toast.error((error as any) ?? errorMessage);
      return;
    } finally {
      setLoading!(false);
    }

    // create the deposit and dispose
    fetch(`${BASE_URL}store/deposits`, {
      method: "POST",
      body: JSON.stringify({
        amount: data.amount,
        proof: preppedImages[0],
        currency: props.depositAddress!.currency!
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        
        const { deposit }: { deposit: Deposit } = await response.json();
        // close dialog

        toast.warning(
          "Your account would be credited after successful confirmation"
        );

        props.closeModal(deposit);
        reset();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured" );
      });
  };

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}
              <div
                className="align-middle max-w-[750px] rounded-lg bg-white dark:bg-darkblack-600 p-6 pb-10 transition-all relative inline-block "
                style={{ textAlign: "left" }}
              >
                {/* inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6 */}
                <header>
                  <div>
                    <h3 className="font-bold text-bgray-900 dark:text-white text-2xl mb-1">
                      Deposit
                    </h3>
                    <p className="text-sm text-bgray-600 dark:text-darkblack-300">
                      Scan the QR code to make a transfer or copy the address
                      and attach an receipt for confirmation
                    </p>
                  </div>
                  <div className="absolute top-0 right-0 pt-5 pr-5">
                    <button
                      type="button"
                      id="step-1-cancel"
                      onClick={() => props.closeModal()}
                      className="rounded-md bg-white dark:bg-darkblack-500 focus:outline-none"
                    >
                      <span className="sr-only">Close</span>
                      <DialogCloseIcon />
                    </button>
                  </div>
                </header>
                <form onSubmit={handleSubmit(createDeposit)}>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6 pt-4">
                    <div>

                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        padding: "4px",
                        maxWidth: 200,
                        width: "100%",
                      }}
                    >
                    <img src={props.depositAddress?.qrImage} style={{width: 256, height:'auto'}}/>

                      {/* <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={store!.metadata!["address"]! as string}
                        viewBox={`0 0 256 256`}
                      /> */}
                      
                      
                    </div><div className="w-full flex flex-col">
                        <label
                          htmlFor="address"
                          className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                        >
                          Network
                        </label>
                        <input
                          readOnly
                          disabled
                          value={props.depositAddress?.network ?? '' as string}
                          type="text"
                          className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                        />
                      </div>
                    </div>


                    <div>
                      <div className="flex flex-col mb-4">
                        <label
                          htmlFor="address"
                          className="text-base text-bgray-600 dark:text-bgray-50  font-medium"
                        >
                          Address
                        </label>
                        <input
                          readOnly
                          value={props.depositAddress?.address ?? '' as string}
                          type="text"
                          className="bg-bgray-50 dark:bg-darkblack-500 dark:text-white p-4 rounded-lg h-14 border-0 focus:border focus:border-success-300 focus:ring-0"
                        />
                      </div>
                      <div className="w-full rounded-lg border border-bgray-200 dark:border-darkblack-400 focus-within:border-success-300 p-4 h-[98px] flex flex-col mb-4 justify-between">
                        <p className="text-sm text-bgray-600 dark:text-darkblack-300 font-medium">
                          Amount
                        </p>
                        <div className="w-full h-[35px] flex justify-between items-center">
                          <span className="mr-4 text-2xl text-bgray-900 dark:text-white font-bold">
                            {props.depositAddress?.currency ?? 'USDT'}
                          </span>
                          <label className="w-full">
                            <input
                              type="text"
                              {...register("amount", { required: true })}
                              className="focus:outline-none w-full p-0 focus:ring-0 border-none text-2xl font-bold text-bgray-900 dark:bg-darkblack-600 dark:text-white"
                            />
                          </label>
                        </div>
                      </div>
                {errors.amount?.message && (
                  <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                    {errors.amount.message}
                  </p>
                )}
                      {useMemo(
                        () => (
                          <FilePond
                            filePosterMaxHeight={200}
                            acceptedFileTypes={[
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                              "image/webp",
                            ]}
                            server={{
                              load: (
                                source,
                                load,
                                error,
                                progress,
                                abort,
                                headers
                              ) => {
                                var myRequest = new Request(source);
                                fetch(myRequest)
                                  .then(function (response) {
                                    response.blob().then(function (myBlob) {
                                      load(myBlob);
                                    });
                                  })
                                  .catch(error);
                              },
                            }}
                            files={proofImageFields.map((field) => ({
                              options: {
                                type: "local",
                              },
                              source: (field as any).url,
                            }))}
                            onaddfile={(_, file) => {
                              if (_) return console.error(_);
                              replaceProofImage([
                                {
                                  url: URL.createObjectURL(file.file),
                                  name: file.filename,
                                  size: file.fileSize,
                                  nativeFile: file.file as any,
                                },
                              ]);
                            }}
                            allowMultiple={false}
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='Drag & Drop your receipt or <span class="filepond--label-action">Browse</span>'
                          />
                        ),
                        []
                      )}
                      
                {errors.proof?.message && (
                  <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                    {errors.proof.message}
                  </p>
                )}
                    </div>
                  </div>
                  <div className="flex justify-center pt-11">
                    <button
                    onClick={handleSubmit(createDeposit)}
                      className="bg-success-300 hover:bg-success-400 text-white font-semibold text-sm py-4 flex justify-center items-center rounded-lg px-20"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
