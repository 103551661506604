import { reduce } from "lodash";
import { Link, NavLink, Navigate, useLoaderData } from "react-router-dom";
import { ReactNode, useState } from "react";
import UserIcon, {
  AvailablePlansFlatIcon,
  FAQIcon,
  MoneyBagIcon,
  MyPlansFlatIcon,
  SecurityIcon,
  UserIconFlat,
} from "../icons/user.icon";
import IntegrationIcon from "../icons/integration.icon";
import LogoutIcon from "../icons/logout.icon";
import TransactionIcon from "../icons/transaction.icon";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";
import { Customer } from "@medusajs/medusa";

export const MENU_GROUPS: {
  label: string;
  children: {
    label: string;
    icon: ReactNode;
    path?: string;
    action?: (ctx: React.Component) => void;
  }[];
}[] = [
  {
    label: "Menu",
    children: [
      {
        label: "Update Profile",
        icon: <UserIconFlat />,
        path: "/home/profile/update",
      },
      {
        label: "Update Password",
        icon: <SecurityIcon />,
        path: "/home/profile/security",
      },
      {
        label: "FAQs",
        icon: <FAQIcon />,
        path: "/home/profile/faqs",
      },
    ],
  },
  {
    label: "Plans",
    children: [
      {
        label: "Investment Plans",
        icon: <AvailablePlansFlatIcon />,
        path: "/home/plans/available",
      },
      {
        label: "My Plans",
        icon: <MyPlansFlatIcon />,
        path: "/home/plans/subscribed",
      },
    ],
  },
  {
    label: "Statements",
    children: [
      {
        label: "Deposits",
        icon: <AvailablePlansFlatIcon />,
        path: "/home/statement/deposits",
      },
      {
        label: "Withdrawal Requests",
        icon: <MyPlansFlatIcon />,
        path: "/home/statement/withdrawals",
      },
      {
        label: "Fund Wallet",
        icon: <MoneyBagIcon />,
        path: "/home/statement/fund-wallet",
      },
    ],
  },
  {
    label: "Other",
    children: [
      {
        label: "Logout",
        icon: <LogoutIcon />,
        action: (ctx: React.Component) => {
          localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
          ctx.setState({ logoutSuccessful: true });
        },
      },
    ],
  },
];
const MobileMenu = () => {
  const [state, setState] = useState<{
    logoutSuccessful: boolean;
  }>({
    logoutSuccessful: false,
  });

  const customer: Customer = useLoaderData() as Customer;


  return (
    <div className="gap-y-4 flex flex-col">
      <div className="grid grid-cols-3 bg-white dark:bg-darkblack-600 rounded-xl px-4 py-6">
        {reduce(
          MENU_GROUPS.map((group) => group.children),
          (a, b) => [...a, ...b]
        )?.map((item, index) => (
          <>
            {item.action ? (
              <div
                key={index}
                onClick={() => item.action!({ setState } as any)}
                className={`tab flex flex-col gap-y-4 p-4 rounded-lg transition-all`}
                data-tab="tab1"
              >
                <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                  {item.icon}
                </div>
                <div>
                  <h4 className="text-center text-base font-bold text-bgray-900 dark:text-white">
                    {item.label}
                  </h4>
                </div>
              </div>
            ) : (
              <NavLink to={item.path!} key={index}>
                {({ isActive, isPending }) => (
                  <div
                    key={index}
                    className={`tab ${
                      isActive ? "active" : ""
                    } flex flex-col gap-y-4 p-4 rounded-lg transition-all`}
                    data-tab="tab1"
                  >
                    <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="text-center text-base font-bold text-bgray-900 dark:text-white">
                        {item.label}
                      </h4>
                    </div>
                  </div>
                )}
              </NavLink>
            )}
          </>
        ))}

        <div className=" col-span-3">
          <div className="bg-bgray-200 dark:bg-darkblack-500 p-7 rounded-xl">
            <div className="flex-row space-x-6 2xl:flex-row 2xl:space-x-6 flex md:flex-col md:space-x-0 items-center">
              <div className="flex flex-col md:items-center xl:items-start items-start">
                <h4 className="text-bgray-900 dark:text-white text-base font-bold">
                  Complete Profile
                </h4>
                <span className="text-xs font-medium text-bgray-700 dark:text-darkblack-300">
                  Complete your profile to unlock all features
                </span>
              </div>
            </div>

            <Link to={"/home/profile/verify"}>
              <button className="w-full mt-4 bg-success-300 hover:bg-success-400 text-white font-bold text-xs py-3 rounded-lg transition-all">
                Verify identify
              </button>
            </Link>
          </div>

          {(customer as any).referralCode && <div className="bonus-box w-full flex justify-center relative mt-4">
            <div className="absolute left-[10px] top-[5px] h-[200px] bg-success-200 rounded-lg"></div>
            <div className="w-full p-5 bg-success-300 rounded-lg relative">
              <h3 className="text-[28px] text-white font-bold text-center">
                {(customer as any).referralCode}
              </h3>
              <p className="text-lg font-semibold text-success-100 leading-[24px] text-center px-5 mb-3">
                Refer a friend using your referral code and earn more
              </p>
            </div>
          </div>}
        </div>
      </div>
      <div className="upgrade-wrapper w-full h-[172px] mb-[26px]">
        <div
          className="w-full h-full rounded-lg upgrade-banner relative"
          style={{
            backgroundImage: "url(/images/bg/upgrade-bg.png)",
          }}
        >
          <div
            style={{ left: "calc(50% - 20px)", top: "-20px" }}
            className="w-10 flex justify-center items-center h-10 rounded-full bg-success-300 border border-white absolute"
          >
            <span>
              <MoneyBagIcon />
            </span>
          </div>
          <h1 className="font-bold text-white text-xl text-center pt-8 mb-2">
            Need Help?
          </h1>
          <p className="text-sm leading-5 text-white opacity-[0.5] text-center px-7 mb-2">
            Contact our 24/7 customer support center
          </p>
        </div>
      </div>

      {state.logoutSuccessful && <Navigate to="/login" />}

      <div className="copy-write-text mx-auto text-center">
        <p className="text-sm text-[#969BA0]">© 2024 All Rights Reserved</p>
        <p className="text-sm text-bgray-700 font-medium">ZenithInvestments</p>
      </div>
    </div>
  );
};

export default MobileMenu;
