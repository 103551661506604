import React, { useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { DialogCloseIcon } from "../../../icons/user.icon";
import { BASE_URL, Investment, Withdrawal } from "../../../utils";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { Customer } from "@medusajs/medusa";

declare var Chart: any;

class ChartInvestmentProfits extends React.Component<
  {
    customer?: Customer;
    investment?: Investment;
    closeModal: () => void;
  },
  { range?: string }
> {
  constructor(props) {
    super(props);
    this.state = {};

    this.canvasRef = React.createRef();
  }
  isDrawn: boolean = false;
  canvasRef: any;

  componentDidMount(): void {
    if (this.isDrawn) return;
    this.isDrawn = true;
    //drawer

    setTimeout(() => {
      this.refresh();
    }, 1200);
  }

  revenueFlowChart: any;
  async refresh(range: string = "7D") {
    if (this.state.range == range) return;
    this.setState({ range: range });

    fetch(
      `${BASE_URL}store/investments/${this.props.investment!
        .id!}/chart?range=${range}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }
    )
      .then(async (r1) => {
        if (r1.status >= 400 && r1.status < 600) {
          const { message }: { message: string } = await r1.json();
          throw new Error(message);
        }

        const { data }: { data: { x: string; y: number }[] } = await r1.json();

        let revenueFlowElement = this.canvasRef.current.getContext("2d");
        let month = data.map((x) => DateTime.fromISO(x.x).toFormat("dd MMM"));
        this.revenueFlowChart?.destroy();
         this.revenueFlowChart = new Chart(revenueFlowElement, {
          type: "line",
          plugins: [
            {
              beforeDatasetsDraw(chart) {
                chart.ctx.shadowColor = "rgba(37, 99, 235, 0.14)";
                chart.ctx.shadowBlur = 8;
              },
              afterDatasetsDraw(chart) {
                chart.ctx.shadowColor = "rgba(0, 0, 0, 0)";
                chart.ctx.shadowBlur = 0;
              },
            },
          ],
          data: {
            labels: month,
            datasets: [
              {
                label: "Balance",
                data: data.map((x) => x.y),
                backgroundColor: () => {
                  const chart = (
                    document.getElementById("overAllBalance") as any
                  ).getContext("2d");
                  const gradient = (chart as any).createLinearGradient(
                    0,
                    0,
                    0,
                    450
                  );
                  gradient.addColorStop(0, "rgba(34, 197, 94,0.41)");
                  gradient.addColorStop(0.6, "rgba(255, 255, 255, 0)");
                  return gradient;
                },
                borderColor: "#22C55E",
                // pointRadius: 5,
                pointBorderColor: "#ffffff",
                pointBackgroundColor: "#22C55E",
                pointBorderWidth: 4,
                borderWidth: 2,
                fill: true,
                fillColor: "#fff",
                tension: 0.4,
              },
            ],
          },
          options: {
            // layout: {
            //   padding: {
            //     bottom: -20,
            //   },
            // },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                grid: {
                  color: "rgb(243 ,246, 255 ,1)",
                },
                gridLines: {
                  zeroLineColor: "transparent",
                },
              },
              y: {
                beginAtZero: true,
                grid: {
                  color: "rgb(243 ,246, 255 ,1)",
                  borderDash: [5, 5],
                  borderDashOffset: 2,
                },
                gridLines: {
                  zeroLineColor: "transparent",
                },
                ticks: {
                  callback(value) {
                    return `${value}`;
                  },
                },
              },
            },

            plugins: {
              legend: {
                position: "top",
                display: false,
              },
              title: {
                display: false,
                text: "Visitor: 2k",
              },
              // tooltip: {
              //   enabled: false,
              // },
            },
            elements: {
              point: {
                radius: 6,
                hoverRadius: 6,
              },
            },
          },
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
        this.props.closeModal();
      });
  }

  render() {
    return (
      <>
        <div className="w-full xl:flex xl:space-x-[24px]">
          <div className="w-full bg-white dark:bg-darkblack-600 flex flex-col justify-between rounded-lg p-4">
            <div className="flex justify-between items-center pb-2 mb-2">
              <div>
                <span className="text-sm font-medium text-bgray-600 dark:text-white">
                  Growth over time for {`${this.props.customer?.last_name ?? ''} ${this.props.customer?.first_name ?? ''}`.trim()}
                </span>
                <div className="flex items-center space-x-2">
                  <h3 className="text-2xl text-bgray-900 font-bold leading-[36px] dark:text-white">
                    {this.props.investment &&
                      new Intl.NumberFormat("en-US", {
                        currency: "USD",
                        style: "currency",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(this.props.investment!.balance)}
                  </h3>
                  {/* <span className="text-sm font-medium text-success-300 dark:text-white">
                    +20%
                  </span> */}
                </div>
              </div>

              <div className="date-filter pl-8 md:pl-16 relative">
                <div className="flex items-center">
                  <button
                    onClick={() => this.refresh("7D")}
                    type="button"
                    
                    className={
                      this.state.range == "7D"
                        ? "rounded-lg text-success-300 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 bg-success-50 dark:bg-darkblack-500 dark:text-bgray-50"
                        : "rounded-lg text-bgray-500 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 hover:bg-success-50 hover:text-success-300 transition duration-300 ease-in-out dark:hover:bg-darkblack-500"
                    }
                  >
                    7D
                  </button>
                  <button
                    onClick={() => this.refresh("4W")}
                    type="button"
                    className={
                      this.state.range == "4W"
                        ? "rounded-lg text-success-300 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 bg-success-50 dark:bg-darkblack-500 dark:text-bgray-50"
                        : "rounded-lg text-bgray-500 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 hover:bg-success-50 hover:text-success-300 transition duration-300 ease-in-out dark:hover:bg-darkblack-500"
                    }
                  >
                    4W
                  </button>
                  <button
                    onClick={() => this.refresh("3M")}
                    type="button"
                    className={
                      this.state.range == "3M"
                        ? "rounded-lg text-success-300 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 bg-success-50 dark:bg-darkblack-500 dark:text-bgray-50"
                        : "rounded-lg text-bgray-500 lg:text-sm text-xs font-bold lg:px-6 lg:py-2.5 px-4 py-1.5 hover:bg-success-50 hover:text-success-300 transition duration-300 ease-in-out dark:hover:bg-darkblack-500"
                    }
                  >
                    3M
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full">
              <canvas
                ref={this.canvasRef}
                id="overAllBalance"
                height="280"
              ></canvas>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const ChartInvestmentProfitsDialog = (props: {
  investment?: Investment;
  customer?: Customer;
  isOpen: boolean;
  closeModal: () => void;
}) => {
  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}

              <div
                style={{ maxWidth: "calc(100vw - 10%)" }}
                className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 "
              >
                <ChartInvestmentProfits
                  closeModal={props.closeModal}
                  investment={props.investment}
                  customer={props.customer}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export { ChartInvestmentProfits, ChartInvestmentProfitsDialog };
