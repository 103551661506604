import { Product } from "@medusajs/medusa";
import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ChooseInvestmentPlanDialog } from "./components/choose_investment_plan";
import FundWalletComponent from "./components/fund_wallet_component";
import DepositHistoryTable from "./components/deposit_history";

const AvailablePlansFragment = () => {
  const products2: Product[] = useLoaderData() as Product[];
  console.error(products2);
  const navigate = useNavigate();

  const [state, setState] = useState<{
    isInvestmentPlanDialogOpen: boolean;
    product?: Product;
  }>({
    isInvestmentPlanDialogOpen: false,
  });

  return (
    <>
      {/* <!-- Cards --> */}
      <div>
        <h3 className="text-2xl font-bold pb-5 text-bgray-900 dark:text-white">
          Available Products
        </h3>
        <div className="grid 2xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-10 gap-5 pb-14">
          {products2.map((product, index) => (
            <div
              className="relative p-6 bg-gray-100 dark:bg-darkblack-500 rounded-lg"
              key={index}
            >
              {product.thumbnail && (
                <div className="flex gap-x-2 mb-5">
                  <img src={product.thumbnail} alt="" />
                </div>
              )}
              <h4 className="text-base font-bold text-bgray-900 dark:text-white mb-2">
                {product.title}
              </h4>
              <p className="text-sm dark:text-bgray-50">{product.subtitle}</p>

              <button
                onClick={() =>
                  setState({
                    isInvestmentPlanDialogOpen: true,
                    product: product,
                  })
                }
                className="w-full mt-4 bg-success-300 hover:bg-success-400 text-white font-bold text-xs py-3 rounded-lg transition-all"
              >
                Invest
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* <!-- Slider --> */}

      <ChooseInvestmentPlanDialog
        product={state.product}
        isOpen={state.isInvestmentPlanDialogOpen}
        closeModal={(success?: boolean) => {
          setState({ isInvestmentPlanDialogOpen: false });
          if (success) navigate(".", { replace: true });
        }}
      ></ChooseInvestmentPlanDialog>
    </>
  );
};
export default AvailablePlansFragment;
