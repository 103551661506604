import React from "react";

export class FAQsFragment extends React.Component<{}, {}> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  componentDidMount(): void {
    //Faq
    const accordionHeader = document.querySelectorAll(".accordion-header");
    accordionHeader.forEach((header) => {
      header.addEventListener("click", function () {
        const accordionContent =
          header.parentElement!.querySelector(".accordion-content");
        let accordionMaxHeight = (accordionContent! as any).style.maxHeight;

        if (accordionMaxHeight == "0px" || accordionMaxHeight.length == 0) {
          (accordionContent! as any).style.maxHeight = `${
            accordionContent!.scrollHeight + 32
          }px`;
          header!.querySelector(".fas")!.classList.remove("fa-plus");
          header!.querySelector(".fas")!.classList.add("fa-minus");
          header!.querySelector(".title")!.classList.add("font-bold");
        } else {
          (accordionContent! as any).style.maxHeight = `0px`;
          header!.querySelector(".fas")!.classList.add("fa-plus");
          header!.querySelector(".fas")!.classList.remove("fa-minus");
          header!.querySelector(".title")!.classList.remove("font-bold");
        }
      });
    });
  }
  render() {
    const faqs: { q: string; a: string }[] = [
      {
        q: "What is a stock?",
        a: "A stock represents ownership in a company. When you buy shares of a company's stock, you essentially own a small piece of that company.",
      },
      {
        q: "How do I start investing?",
        a: "To start investing, you'll need to open a brokerage account. You can do this online with various brokerage firms. Once your account is set up, you can deposit funds and start buying investments such as stocks, bonds, or mutual funds.",
      },
      {
        q: "What is diversification?",
        a: "Diversification is a risk management strategy that involves spreading your investments across a variety of assets. By diversifying, you can reduce the impact of any single investment's performance on your overall portfolio.",
      },
      {
        q: "What are dividends?",
        a: "Dividends are payments made by a company to its shareholders. They are typically a portion of the company's profits. Some companies pay dividends regularly, while others may pay them on a less frequent basis or not at all.",
      },
      {
        q: "What is a mutual fund?",
        a: "A mutual fund is a type of investment vehicle that pools money from multiple investors to invest in stocks, bonds, or other assets. Mutual funds are managed by professional fund managers, who make investment decisions on behalf of the fund's investors.",
      },
    ];
    return (
      <>
        <div>
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-white">
            FAQ
          </h3>
          <div>
            {/* <!-- Faq 1 --> */}
            {faqs.map((item, index) => (
              <div className="transition" key={index}>
                <div className="accordion-header border-b border-bgray-300 dark:border-darkblack-400 cursor-pointer transition flex space-x-5 items-center h-16">
                  <i className="fas fa-minus text-success-300 text-xl"></i>
                  <h2 className="title transition-all text-bgray-900 dark:text-white md:text-lg text-sm font-bold ">
                    {item.q}
                  </h2>
                </div>
                <div
                  style={{ maxHeight: 164 }}
                  className="accordion-content lg:pl-8 pl-4 pt-0 overflow-hidden max-h-0 space-y-4"
                >
                  <div className="flex flex-row py-6">
                    <div className="flex h-[93] w-1 bg-success-300 rounded-lg"></div>
                    <div className="flex-1">
                      <p className="text-[#9AA2B1] pl-4 lg:text-base text-xs lg:leading-7">
                        {item.a}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
export default FAQsFragment;
