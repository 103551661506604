import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

type AccountStatementRequestedDialogProps = {
  isOpen: boolean;
  closeModal: () => void;
};
type AccountStatementRequestedDialogState={};


class RolloverRequiredDialog extends React.Component<
  AccountStatementRequestedDialogProps,
  AccountStatementRequestedDialogState
> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  closeModal() {
    this.props.closeModal();
  }
  render(): React.ReactNode {
    return (
      <Transition.Root show={this.props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => this.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6">
                <button
                  id="step-1-cancel"
                  onClick={() => this.closeModal()}
                  className="flex justify-center items-center absolute top-6 right-6"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9492 7.05029L7.04972 16.9498"
                      stroke="#22C55E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.0498 7.05029L16.9493 16.9498"
                      stroke="#22C55E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="w-full">
                  <h3 className="text-lg font-bold text-bgray-900 dark:text-white mb-4">
                  Premature Withdrawal Notice
                  </h3>
                  <div className="w-full rounded-lg dark:border-darkblack-400 p-4 h-[128px] flex flex-col justify-between">
                    <p className="text-sm text-bgray-600 dark:text-bgray-50 font-medium">
                    To maximize your returns and fully benefit from our structured investment program, we kindly remind you that withdrawals before completing the final investment plan are not permitted, Consider rolling over your current investment to the next available option.
                    </p>
                  </div>

                  <button
                    className="bg-success-300 hover:bg-success-400 transition-all text-white py-4 w-full font-bold rounded-lg mt-14"
                    onClick={() => this.closeModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

export { RolloverRequiredDialog };
