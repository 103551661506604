import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa } from "medusa-react";
import { Fragment, useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { LoadingContext } from "../../..";
import { DialogCloseIcon } from "../../../icons/user.icon";
import { Investment, Withdrawal } from "../../../utils";

export const ForgotPasswordDialog = (props: {
  isOpen: boolean;
  closeModal: (withdrawal?: Withdrawal) => void;
}) => {
  const schema = yup
    .object({
      email: yup.string().required().email(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: null,
    } as any,
    resolver: yupResolver(schema as any),
  });

  const { setLoading } = useContext(LoadingContext);
  const { client: medusaClient } = useMedusa();

  const requestPasswordReset = async (data: { email: string }) => {
    console.error(data);
    setLoading!(true);

    medusaClient.customers
      .generatePasswordToken(data)
      .then((res) => {
        toast.success("if you have an account with us, we've sent you an email with further instructions");
        setLoading!(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "An error occured");
        setLoading!(false);
      }).finally(() => {
        reset();
        props.closeModal();
      });
  };

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}

              <div className="max-w-[492px]  relative inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6">
              {/* <div className="relative max-w-[492px] transform overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 p-8 text-left transition-all"> */}
                <div className="absolute top-0 right-0 pt-5 pr-5">
                  <button
                    type="button"
                    onClick={() => props.closeModal()}
                    className="rounded-md bg-white dark:bg-darkblack-500 focus:outline-none"
                  >
                    <span className="sr-only">Close</span>
                    <DialogCloseIcon />
                  </button>
                </div>
                <div>
                  <a  href="https://zenithin.com" className="block mb-7 w-[136px]">
                  <div className="bg-white rounded-md">
                  <img
                    src="/images/logo/logo.png"
                    className="w-[136px] px-4 py-2"
                  />
                </div>
                  </a>
                  <h3 className="text-2xl font-bold text-bgray-900 dark:text-white mb-3">
                    Reset your password
                  </h3>
                  <p className="text-base font-medium text-bgray-600 dark:text-darkblack-300 mb-7">
                    Enter the email address associated with your account and
                    we'll send you a link to reset your password.
                  </p>
                  <form onSubmit={handleSubmit(requestPasswordReset)}>
                    <div className="mb-8">
                      <input
                        type="email"
                        {...register("email", { required: true })}
                        className="rounded-lg bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-white p-4 border-0 focus:border focus:ring-0 focus:border-success-300 w-full placeholder:font-medium text-base h-14"
                        placeholder="Email"
                      />

                      {errors.email?.message && (
                        <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    <a
                      href="#"
                      onClick={() => props.closeModal()}
                      className="block text-sm font-bold text-blue-300 mb-8 underline"
                    >
                      Return to login
                    </a>
                    <button
                  type="submit"
                      className="flex w-full py-4 text-white bg-blue-500  hover:bg-blue-400  transition-all justify-center text-base font-medium rounded-lg"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
