import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Product } from "@medusajs/medusa";
import { reduce } from "lodash";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { DialogCloseIcon } from "../../../icons/user.icon";
import { Account, BASE_URL, StoreDepositAddress } from "../../../utils";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";
import { WalletBalancesCardComponent } from "./wallet_balances";

export type ChooseInvestmentFormType = {
  productVariantId?: string;
  amount?: number;
  currency?: string;
};

export const ChooseInvestmentPlanDialog = (props: {
  product?: Product;
  isOpen: boolean;
  closeModal: (success?: boolean) => void;
}) => {
  const schema = yup
    .object({
      productVariantId: yup.string().required(),
      amount: yup.number().required(),
      currency: yup.string().required().oneOf(["USDT", "ETH", "BTC"]),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<ChooseInvestmentFormType>({
    defaultValues: {
      productVariantId: null,
      amount: 0,
      currency: undefined,
    } as any,
    resolver: yupResolver(schema as any),
  });

  // a map of option ids to their titles
  const optionIdToOptionTitle: Map<string, string> = props.product?.id
    ? (reduce(
        props.product.options.map((option) => ({ [option.id]: option.title })),
        (a, b) => ({ ...a, ...b })
      ) as any)
    : {};

  // the meta items
  const meta: {
    variantId: string;
    label: string;
    parameters: {
      key: string;
      value: string;
    }[];
  }[] = props.product?.id
    ? props.product.variants.map((variant) => ({
        variantId: variant.id,
        label: variant.title,
        parameters: variant.options
          .map((option) => ({
            key: optionIdToOptionTitle![option.option_id],
            value: option.value,
          }))
          .sort((a, b) => ((a.key as string) > (b.key as string) ? 0 : 1)),
      }))
    : [];

  const [state, setState] = useState<{
    accounts?: Account[];
    currency?: string;
    depositAddresses?: StoreDepositAddress[];
  }>({});

  const commenceInvestment = async (data: ChooseInvestmentFormType) => {
    console.error(data);

    // create the deposit and dispose
    fetch(`${BASE_URL}store/investments/${props.product!.id}/subscribe`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        toast.success("Investment option registered");

        props.closeModal(true);
        reset();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      });
  };

  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}
              <div
                className="align-middle rounded-lg bg-white dark:bg-darkblack-600 p-6 pb-10 transition-all relative inline-block "
                style={{ textAlign: "left", maxWidth: "calc(100vw - 10%)" }}
              >
                {/* inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 bg-white dark:bg-darkblack-600 dark:border dark:border-darkblack-400 px-[42px] py-5 2xl:mb-6 lg:mb-0 mb-6 */}
                <header>
                  <div>
                    <h3 className="font-bold text-bgray-900 dark:text-white text-2xl mb-1">
                      Choose Investment Plan
                    </h3>
                    <p className="text-sm text-bgray-600 dark:text-darkblack-300">
                      Choose a plan that fits within your budget and expected
                      returns.
                    </p>
                  </div>
                  <div className="absolute top-0 right-0 pt-5 pr-5">
                    <button
                      type="button"
                      id="step-1-cancel"
                      onClick={() => props.closeModal()}
                      className="rounded-md bg-white dark:bg-darkblack-500 focus:outline-none"
                    >
                      <span className="sr-only">Close</span>
                      <DialogCloseIcon />
                    </button>
                  </div>
                </header>
                <div className="pt-4 mb-10" style={{ overflowX: "scroll" }}>
                  <div className="grid 2xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-10 gap-5">
                    {meta.map((item, index) => (
                      <div
                        onClick={() =>
                          setValue("productVariantId", item.variantId)
                        }
                        className="relative p-6 bg-gray-100 dark:bg-darkblack-500 rounded-lg"
                        key={index}
                      >
                        <input
                          value={item.variantId}
                          {...register("productVariantId", {
                            required: true,
                          })}
                          type="radio"
                          className="absolute top-5 right-5 mr-4 h-5 w-5 cursor-pointer rounded-full border border-bgray-400 text-success-300 focus:outline-none focus:ring-white dark:border-darkblack-400 dark:bg-darkblack-600"
                        />

                        <h4 className="text-base font-bold text-bgray-900 dark:text-white mb-2  flex">
                          {item.label}

                          <div className="w-[60px] h-[22px] rounded-full bg-success-400 flex ml-2 justify-center items-center">
                            <div className="flex space-x-1 items-center">
                              <span>
                                <svg
                                  width="10"
                                  height="7"
                                  viewBox="0 0 10 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.5 5.89575L3.5 2.89575L5.5 4.89575L9.5 0.895752"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                  <path
                                    d="M6 0.895752H9.5V4.39575"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </span>
                              <span className="text-white text-xs font-medium">
                                {
                                  item.parameters.find((e) => e.key == "ROI")
                                    ?.value
                                }{" "}
                                %
                              </span>
                            </div>
                          </div>
                        </h4>

                        <p className="text-sm dark:text-bgray-50">
                          {
                            item.parameters.find((e) => e.key == "Duration")
                              ?.value
                          }
                          {"  ROI on investments of "}
                          {new Intl.NumberFormat("en-US", {
                            currency: "USD",
                            style: "currency",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            item.parameters.find((e) => e.key == "Min")
                              ?.value as any
                          )}
                          {" - "}
                          {new Intl.NumberFormat("en-US", {
                            currency: "USD",
                            style: "currency",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(
                            item.parameters.find((e) => e.key == "Max")
                              ?.value as any
                          )}
                        </p>
                      </div>
                    ))}
                  </div>

                  {errors.productVariantId?.message && (
                    <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                      {errors.productVariantId.message}
                    </p>
                  )}
                </div>
                <form onSubmit={handleSubmit(commenceInvestment)}>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6">
                    <WalletBalancesCardComponent
                      setMetadata={(data) => {
                        setState({ ...state, ...data });
                      }}
                    />
                    <div>
                      <label
                        htmlFor="countries"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select an option
                      </label>
                      <select
                        {...register("currency", { required: true })}
                        onChange={(ev) => {
                          setState({ ...state, currency: ev.target.value });
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 w-full h-[56px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Choose a wallet</option>
                        {state.depositAddresses &&
                          state.depositAddresses.map((depositAddress) => (
                            <option value={depositAddress.currency}>
                              {depositAddress.currency} - Balance{" "}
                              {
                                state.accounts?.find(
                                  (account) =>
                                    account.tag.indexOf(
                                      `:${depositAddress.currency}`
                                    ) > -1
                                )?.balance
                              }{" "}
                              {depositAddress.currency.toUpperCase() !=
                                "USDT" && (
                                <>
                                  &nbsp;(
                                  {Math.floor(
                                    state.accounts?.find(
                                      (account) =>
                                        account.tag.indexOf(
                                          `:${depositAddress.currency}`
                                        ) > -1
                                    )?.equiv ?? 0
                                  )}
                                  USD)
                                </>
                              )}
                            </option>
                          ))}
                      </select>
                      {errors.currency?.message && (
                        <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                          {errors.currency.message}
                        </p>
                      )}

                      <div className="mt-4 w-full rounded-lg border border-bgray-200 dark:border-darkblack-400 focus-within:border-success-300 p-4 h-[98px] flex flex-col justify-between">
                        <p className="text-sm text-bgray-600 dark:text-darkblack-300 font-medium">
                          How much would you like to invest right now?
                        </p>
                        <div className="w-full h-[35px] flex justify-between items-center">
                          <span className="mr-4 text-2xl text-bgray-900 dark:text-white font-bold">
                            {state.currency ?? "USDT"}
                          </span>
                          <label className="w-full">
                            <input
                              type="text"
                              {...register("amount", { required: true })}
                              className="focus:outline-none w-full p-0 focus:ring-0 border-none text-2xl font-bold text-bgray-900 dark:bg-darkblack-600 dark:text-white"
                            />
                          </label>
                        </div>
                      </div>

                      {errors.amount?.message && (
                        <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                          {errors.amount.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center pt-11">
                    <button
                      onClick={() => handleSubmit(commenceInvestment)}
                      className="bg-success-300 hover:bg-success-400 text-white font-semibold text-sm py-4 flex justify-center items-center rounded-lg px-20"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
