import { useState } from "react";
import { DepositDialog } from "../../../components/transfer_and_upload_proof_dialog";
import { WalletBalancesCardComponent } from "./wallet_balances";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, useFieldArray } from "react-hook-form";
import { Account, Deposit, StoreDepositAddress } from "../../../utils";
import { LoadingRing } from "../../../components/loading_dialog";
import { useRouteLoaderData } from "react-router-dom";
import { Store } from "@medusajs/medusa";

const FundWalletComponent = () => {
  const [state, setState] = useState<{
    isDepositDialogOpen: boolean;
    accounts?: Account[];
    currency?: string;
    depositAddresses?: StoreDepositAddress[];
  }>({
    isDepositDialogOpen: false,
  });

  const openDepositDetailsDialog = async (data: { currency: string }) => {
    console.error(data);
    setState({ ...state, currency: data.currency, isDepositDialogOpen: true });
  };

  const { store } = useRouteLoaderData("root") as { store: Store };

  return (
    <>
      <div className="flex justify-between mb-4 w-full">
        <h4 className="text-bgray-900 font-bold text-lg dark:text-white">
          My Wallets
        </h4>
      </div>

      <WalletBalancesCardComponent
        setMetadata={(data) => {
          setState({ ...state, ...data });
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4">
        {state.depositAddresses &&
          state.depositAddresses.map((depositAddress) => (
            <>
              <div className="w-full rounded-xl bg-white dark:bg-darkblack-600 mb-[48px]">
                <div className="border border-bgray-300 dark:border-darkblack-400 rounded-lg p-8 pb-12">
                  <img
                    src={depositAddress.cryptoLogo}
                    style={{ height: 20, marginBottom: 4 }}
                  />
                  <h2 className="text-4xl font-bold font-poppins text-bgray-900 dark:text-white">
                    {
                      state.accounts?.find(
                        (account) =>
                          account.tag.indexOf(`:${depositAddress.currency}`) >
                          -1
                      )?.balance
                    }
                    <span className="text-base font-medium uppercase text-bgray-500">
                      {depositAddress.currency}
                    </span>
                  </h2>
                  { (
                    <p className="text-bgray-600 text-base font-medium">
                      (
                      {Math.floor(
                        state.accounts?.find(
                          (account) =>
                            account.tag.indexOf(`:${depositAddress.currency}`) >
                            -1
                        )?.equiv ?? 0
                      )}{" "}
                      USD)
                    </p>
                  )}
                </div>
                <div className="flex justify-center -mt-6">
                  <button
                    onClick={() => {
                      openDepositDetailsDialog({
                        currency: depositAddress.currency,
                      });
                    }}
                    className="py-3 px-6 bg-success-300 hover:bg-success-400 rounded-lg text-sm font-medium text-bgray-600  dark:text-white"
                  >
                    Deposit
                  </button>
                </div>
              </div>
            </>
          ))}
      </div>

      <DepositDialog
        depositAddress={state.depositAddresses?.find(
          (depositAddress) => depositAddress.currency == state.currency
        )}
        isOpen={state.isDepositDialogOpen}
        closeModal={(deposit?: Deposit) => {
          setState({ ...state, isDepositDialogOpen: false });
        }}
      ></DepositDialog>
    </>
  );
};

export default FundWalletComponent;
