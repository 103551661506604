import { useEffect, useState } from "react";
import { NextPageIcon, PreviousPageIcon } from "../../../icons/user.icon";
import { BASE_URL, Deposit, Withdrawal } from "../../../utils";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";
import {
  LoadingRing,
  LoadingRingMaxW,
} from "../../../components/loading_dialog";

const DepositHistoryTable = () => {
  const [state, setState] = useState<{
    count?: number;
    limit?: number;
    offset?: number;
    loadingError?: string;
    loading: boolean;
    deposits?: Deposit[];
    paginationInfo?: { totalPageCount: number; currentPage: number };
  }>({
    limit: 5,
    loading: true,
    offset: 0,
  });

  const hasReachedEnd = () => {
    if (state.offset == 0) return (state.deposits?.length ?? 0) >= state.count!;
    return state.offset! + 1 >= state.count!;
  };

  const nextPage = () => {
    if (hasReachedEnd()) return;

    let newOffset = state.offset;

    if (newOffset == 0) {
      newOffset = 1 * state.limit!;
    } else {
      newOffset = newOffset! + state.limit!;
    }
    return `?offset=${newOffset}&limit=${state.limit}`;
  };
  const previousPage = () => {
    let newOffset = state.offset;

    if (newOffset == 0) {
      return;
    } else {
      newOffset = newOffset! - state.limit!;
    }
    return `?offset=${newOffset}&limit=${state.limit}`;
  };

  let lastQP;
  const refresh = (queryParamaeters?: string) => {
    if (!queryParamaeters) return;
    lastQP = queryParamaeters;
    setState({ ...state, loadingError: undefined, loading: true });

    fetch(`${BASE_URL}store/deposits${queryParamaeters ?? ""}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        const data: {
          deposits: Deposit[];
          count: number;
          limit: number;
          offset: number;
        } = await response.json();

        setState({
          ...state,
          ...data,
          loadingError: undefined,
          loading: false,
          paginationInfo: {
            currentPage: Math.ceil((data.offset + data.limit) / data.limit),
            totalPageCount: Math.ceil(data.count / data.limit),
          },
        });
      })
      .catch((error) => {
        console.error(error);
        // show error
        setState({
          ...state,
          loadingError: error?.message ?? "An error occured",
          loading: false,
        });
      });
  };

  useEffect(() => {
    refresh(`?offset=0&limit=5`);
  }, []);

  return (
    <>
      <div className="w-full rounded-lg bg-white dark:bg-darkblack-600">
        <div className="flex flex-col space-y-5">
          <h3 className="text-lg font-bold text-bgray-900 dark:text-white mb-4">
            Deposit History
          </h3>

          <div className="table-content w-full overflow-x-auto">
            <table className="relative w-full p-6 bg-gray-100 dark:bg-darkblack-500 rounded-lg">
              <tbody>
                <tr className="border-b border-bgray-300 dark:border-darkblack-400">
                  <td className="px-6 py-5 ">
                    <div className="flex w-full items-center space-x-2.5">
                      <span className="text-base font-medium text-bgray-600 dark:text-bgray-50">
                        Date
                      </span>
                    </div>
                  </td>
                  {/* <td className="px-6 py-5 ">
                    <div className="flex items-center space-x-2.5">
                      <span className="text-base font-medium text-bgray-600 dark:text-bgray-50">
                        Address
                      </span>
                    </div>
                  </td> */}
                  <td className="px-6 py-5 ">
                    <div className="flex items-center space-x-2.5">
                      <span className="text-base font-medium text-bgray-600 dark:text-bgray-50">
                        Amount
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-5 xl:w-[165px] ">
                    <div className="flex w-full items-center space-x-2.5">
                      <span className="text-base font-medium text-bgray-600">
                        Status
                      </span>
                    </div>
                  </td>
                  <td className="py-5"></td>
                </tr>
                {state.loadingError && (
                  <tr>
                    <td colSpan={100}>
                      <div className="w-full rounded-xl mt-4 mb-[48px]">
                        <div className="border border-bgray-300 dark:border-darkblack-400 rounded-lg p-8 pb-12">
                          <div className="flex gap-4">
                            <span className="text-base font-medium text-bgray-500 dark:text-darkblack-300">
                              an error occured{state.loadingError}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-center -mt-6">
                          <button
                            onClick={() => refresh(lastQP)}
                            className="py-3 px-6 border bg-white border-bgray-500 rounded-lg text-sm font-medium text-bgray-600 dark:bg-darkblack-600 dark:text-white"
                          >
                            Retry
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {state.loading && (
                  <tr>
                    <td colSpan={100}>
                      <LoadingRingMaxW />
                    </td>
                  </tr>
                )}
                {!state.loading &&
                  state.deposits &&
                  (state.deposits.length > 0 ? (
                    state.deposits.map((deposit, index) => (
                      <tr
                        key={index}
                        className="border-b border-bgray-300 dark:border-darkblack-400"
                      >
                        <td className="px-6 py-5 ">
                          <p className="text-base font-medium text-bgray-900 dark:text-white">
                            #{deposit.id.replaceAll("dep_", "")}
                            <br />
                            {new Date(deposit.created_at).toDateString()}
                          </p>
                        </td>
                        {/* <td className="px-6 py-5 ">
                          <p className="text-base font-medium text-bgray-900 dark:text-white">
                            {deposit.address}
                          </p>
                        </td> */}
                        <td className="px-6 py-5 ">
                          <p className="text-base font-medium text-bgray-900 dark:text-white">
                          {deposit.currency} {new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: deposit.currency == 'USDT' ? 2:4,
                              maximumFractionDigits: deposit.currency == 'USDT' ? 2:4,
                            }).format(deposit.amount)}
                          </p>
                        </td>
                        <td className="px-6 py-5 xl:w-[165px] ">
                          <div className="flex w-full items-center">
                            <span
                              className={(() => {
                                if (deposit.status == "approved")
                                  return "block rounded-md bg-[#FDF9E9] px-4 py-1.5 text-sm font-semibold leading-[22px] text-success-300 dark:bg-darkblack-500";

                                if (deposit.status == "pending")
                                  return "block rounded-md bg-[#FDF9E9] px-4 py-1.5 text-sm font-semibold leading-[22px] text-warning-300 dark:bg-darkblack-500";
                                return "block rounded-md bg-[#FDF9E9] px-4 py-1.5 text-sm font-semibold leading-[22px] text-error-300 dark:bg-darkblack-500";
                              })()}
                            >
                              {deposit.status}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={100}>
                        <div className="w-full rounded-xl mt-4 mb-[48px]">
                          <div className="dark:border-darkblack-400 rounded-lg p-8 pb-12">
                            <div className="flex gap-4">
                              <span className="text-base font-medium text-bgray-500 dark:text-darkblack-300">
                                No Data
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-content w-full">
            <div className="flex w-full items-center justify-center lg:justify-between">
              <div className="flex items-center space-x-5 sm:space-x-[35px]">
                <button type="button" onClick={() => refresh(previousPage())}>
                  <span>
                    <PreviousPageIcon />
                  </span>
                </button>
                <div className="flex items-center">
                  {state.paginationInfo &&
                    Array.from(
                      {
                        length:
                          state.paginationInfo.totalPageCount <= 0
                            ? 1
                            : state.paginationInfo.totalPageCount,
                      },
                      (_, index) => index + 1
                    ).map((pageIndex, index) => (
                      <>
                        <button
                          key={index}
                          type="button"
                          disabled={
                            pageIndex == state.paginationInfo?.currentPage
                          }
                          className={(() => {
                            if (pageIndex == state.paginationInfo?.currentPage)
                              return "rounded-lg bg-success-50 px-4 py-1.5 text-xs font-bold text-success-300 dark:bg-darkblack-500 dark:text-bgray-50 lg:px-6 lg:py-2.5 lg:text-sm";
                            return "rounded-lg px-4 py-1.5 text-xs font-bold text-bgray-500 transition duration-300 ease-in-out hover:bg-success-50 hover:text-success-300 dark:hover:bg-darkblack-500 lg:px-6 lg:py-2.5 lg:text-sm";
                          })()}
                        >
                          {pageIndex}
                        </button>
                      </>
                    ))}
                </div>
                <button type="button" onClick={() => refresh(nextPage())}>
                  <span>
                    <NextPageIcon />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositHistoryTable;
