import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { EyeIcon, UpDownChangeInProfitIcon } from "../../icons/user.icon";
import { Investment, Withdrawal } from "../../utils";
import FundWalletComponent from "./components/fund_wallet_component";
import { WithdrawInvestmentPlanDialog } from "./components/withdraw_investment_plan";
import WithdrawalRequestsTable from "./components/withdrawal_requests_history";
import { ChartInvestmentProfitsDialog } from "./components/chart_investment_profits";
import { TopupInvestmentPlanDialog } from "./components/topup_investment_plan";
import { Customer } from "@medusajs/medusa";
import { RolloverRequiredDialog } from "../../components/rollover_required_dialog";

const MyPlansFragment = () => {
  const investments: Investment[] = useLoaderData() as Investment[];

  investments.forEach((e) => {
    e.rollover_required = e.productVariant.metadata
      ? e.productVariant.metadata["rollover_required"] == "yes"
      : false;
  });

  const [state, setState] = useState<{
    isProfitChartDialogOpen: boolean;
    isRolloverRequiredDialogOpen: boolean;
    isWithdrawalDialogOpen: boolean;
    isTopUpDialogOpen: boolean;
    investment?: Investment;
    customer?: Customer;
    topupProduct?: { productId: string; productVariantId: string };
  }>({
    isProfitChartDialogOpen: false,
    isRolloverRequiredDialogOpen: false,
    isWithdrawalDialogOpen: false,
    isTopUpDialogOpen: false,
    investment: undefined,
    customer: undefined,
    topupProduct: undefined,
  });
  const navigate = useNavigate();

  return (
    <>
      {/* <!-- Cards --> */}
      <div>
        <h3 className="text-2xl font-bold pb-5 text-bgray-900 dark:text-white">
          Your Plans
        </h3>
        <div className=" pb-14">
          {investments.length <= 0 ? (
            <div className="w-full rounded-xl bg-white dark:bg-darkblack-600 mb-[48px]">
              <div className="border border-bgray-300 dark:border-darkblack-400 rounded-lg p-8 pb-12">
                <div className="flex w-full gap-4">
                  <span className="text-base font-medium text-bgray-500 dark:text-darkblack-300">
                    No active plans
                  </span>
                </div>
              </div>
              <div className="flex justify-center -mt-6">
                <button
                  onClick={() => navigate("/home/plans/available")}
                  className="py-3 px-6 border bg-white border-bgray-500 rounded-lg text-sm font-medium text-bgray-600 dark:bg-darkblack-600 dark:text-white"
                >
                  Subscribe
                </button>
              </div>
            </div>
          ) : (
            investments.map((investment, index) => (
              <div
                className="relative p-6 bg-gray-100 dark:bg-darkblack-500 rounded-lg"
                key={index}
              >
                {
                  <span className="absolute right-6 top-6">
                    <button
                      type="button"
                      onClick={() =>
                        setState({
                          ...state,
                          isProfitChartDialogOpen: true,
                          investment: investment,
                          customer: investment.user,
                        })
                      }
                      className="absolute top-4 right-4 bottom-4"
                    >
                      <EyeIcon />
                    </button>
                  </span>
                }
                {investment.product.thumbnail && (
                  <div className="flex gap-x-2 mb-5">
                    <img src={investment.product.thumbnail} alt="" />
                  </div>
                )}

                <div className="p-5 mb-4 rounded-lg bg-white  dark:bg-darkblack-600">
                  <div className="flex justify-between items-end">
                    <div className="flex-1">
                      <p className="text-bgray-900 dark:text-white font-bold text-3xl leading-[48px]">
                        {new Intl.NumberFormat("en-US", {
                          currency: "USD",
                          style: "currency",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(investment.balance)}
                      </p>
                      <div className="flex items-center space-x-1">
                        <span>
                          <UpDownChangeInProfitIcon />
                        </span>
                        <span className="text-success-300 text-sm font-medium">
                          +{" "}
                          {`${
                            investment.productVariant.options.find(
                              (x) => x.option.title == "ROI"
                            )?.value
                          }%`}
                        </span>
                        <span className="text-bgray-700 dark:text-bgray-50 text-sm font-medium">
                          {`${
                            investment.productVariant.options.find(
                              (x) => x.option.title == "Duration"
                            )?.value
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <h4 className="text-base font-bold text-bgray-900 dark:text-white mb-2">
                  {investment.product.title}
                </h4>
                <p className="text-sm dark:text-bgray-50">
                  {investment.product.subtitle}
                </p>

                <div className="flex gap-x-4">
                  <button
                    disabled={investment.balance <= 0}
                    onClick={() =>
                      setState({
                        ...state,
                        isWithdrawalDialogOpen: !(
                          investment.rollover_required ?? false
                        ),
                        isRolloverRequiredDialogOpen:
                          investment.rollover_required ?? false,
                        investment: !(investment.rollover_required ?? false)
                          ? investment
                          : undefined,
                      })
                    }
                    className={(() => {
                      if (investment.balance <= 0)
                        return "w-full mt-4 outline disabled text-white font-bold text-xs py-3 rounded-lg transition-all";
                      return "w-full mt-4 bg-success-300 hover:bg-success-400 text-white font-bold text-xs py-3 rounded-lg transition-all";
                    })()}
                  >
                    Withdraw
                  </button>

                  <button
                    onClick={() =>
                      setState({
                        ...state,
                        isTopUpDialogOpen: true,
                        topupProduct: {
                          productId: investment.productId,
                          productVariantId: investment.productVariantId,
                        },
                      })
                    }
                    className="w-full mt-4 bg-success-300 hover:bg-success-400 text-white font-bold text-xs py-3 rounded-lg transition-all"
                  >
                    Top Up
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {/* <!-- Slider --> */}

      {/* <!-- Slider --> */}
      <WithdrawInvestmentPlanDialog
        investment={state.investment}
        isOpen={state.isWithdrawalDialogOpen}
        closeModal={(withdrawal?: Withdrawal) => {
          if (withdrawal) {
            navigate(".", { replace: true });
          }
          setState({
            ...state,
            isWithdrawalDialogOpen: false,
            investment: undefined,
          });
        }}
      ></WithdrawInvestmentPlanDialog>

      {/* <!-- Slider --> */}
      <TopupInvestmentPlanDialog
        productId={state.topupProduct?.productId}
        productVariantId={state.topupProduct?.productVariantId}
        isOpen={state.isTopUpDialogOpen}
        closeModal={(success?: boolean) => {
          if (success) {
            navigate(".", { replace: true });
          }
          setState({
            ...state,
            isTopUpDialogOpen: false,
            topupProduct: undefined,
          });
        }}
      ></TopupInvestmentPlanDialog>

      <ChartInvestmentProfitsDialog
        investment={state.investment}
        customer={state.customer}
        isOpen={state.isProfitChartDialogOpen}
        closeModal={() => {
          setState({
            ...state,
            isProfitChartDialogOpen: false,
            investment: undefined,
            customer: undefined,
          });
        }}
      ></ChartInvestmentProfitsDialog>

      <RolloverRequiredDialog
        isOpen={state.isRolloverRequiredDialogOpen}
        closeModal={() =>
          setState({ ...state, isRolloverRequiredDialogOpen: false })
        }
      ></RolloverRequiredDialog>
    </>
  );
};
export default MyPlansFragment;
