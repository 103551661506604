const WalletIcon = () => {
    return ( <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 4C20 1.79086 18.2091 0 16 0H4C1.79086 0 0 1.79086 0 4V14C0 16.2091 1.79086 18 4 18H16C18.2091 18 20 16.2091 20 14V4Z"
          fill="#1A202C"
          className="path-1"
        />
        <path
          d="M6 9C6 7.34315 4.65685 6 3 6H0V12H3C4.65685 12 6 10.6569 6 9Z"
          fill="#22C55E"
          className="path-2"
        />
      </svg>
    );
  };
  
  export default WalletIcon;
  