import { ReactNode } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { FAQIcon, SecurityIcon, UserIconFlat } from "../icons/user.icon";

const ProfilePage = () => {
  const group: {
    label: string;
    subtitle?: string;
    icon: ReactNode;
    path?: string;
  }[] = [
    {
      label: "Personal Information",
      subtitle: "Update your profile information",
      icon: <UserIconFlat />,
      path: "/home/profile/update",
    },
    {
      label: "Security",
      subtitle: "Change your password",
      icon: <SecurityIcon />,
      path: "/home/profile/security",
    },
    {
      label: "FAQs",
      subtitle: "Frequently asked questions",
      icon: <FAQIcon />,
      path: "/home/profile/faqs",
    },
  ];

  return (
    <>
      {/* <!-- write your code here--> */}
      <div className="grid grid-cols-1 xl:grid-cols-12 bg-white dark:bg-darkblack-600 rounded-xl">
        {/* <!-- Sidebar --> */}
        <aside className="hidden md:block col-span-3 border-r border-bgray-200 dark:border-darkblack-400">
          {/* <!-- Sidebar Tabs --> */}

          <div className="px-4 py-6">
            {group.map((item, index) => (
              <NavLink to={item.path!} key={index}>
                {({ isActive, isPending }) => (
                  <div
                    key={index}
                    className={`tab ${
                      isActive ? "active" : ""
                    } flex gap-x-4 p-4 rounded-lg transition-all`}
                    data-tab="tab1"
                  >
                    <div className="w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="text-base font-bold text-bgray-900 dark:text-white">
                        {item.label}
                      </h4>
                      {item.subtitle && (
                        <p className="text-sm font-medium text-bgray-700 dark:text-darkblack-300 mt-0.5">
                          {item.subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
          <div className="px-8 py-6">
            <div className="bg-bgray-200 dark:bg-darkblack-500 p-7 rounded-xl">
              <div className="flex-row space-x-6 2xl:flex-row 2xl:space-x-6 flex md:flex-col md:space-x-0 items-center">
                <div className="flex flex-col md:items-center xl:items-start items-start">
                  <h4 className="text-bgray-900 dark:text-white text-base font-bold">
                    Complete profile
                  </h4>
                  <span className="text-xs font-medium text-bgray-700 dark:text-darkblack-300">
                    Complete your profile to unlock all features
                  </span>
                </div>
              </div>

              <Link to={"/home/profile/verify"}>
                <button className="w-full mt-4 bg-success-300 hover:bg-success-400 text-white font-bold text-xs py-3 rounded-lg transition-all">
                  Verify identify
                </button>
              </Link>
            </div>
          </div>
        </aside>

        {/* <!--Tab Content --> */}
        <div className="py-8 px-10 col-span-9 tab-content">
          <div className="tab-pane active">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfilePage;
